<template>
  <div class="login_main_app">
    <div v-show="toggle">
      <div>
        <v-snackbar
          v-model="snackbar.visible"
          :color="snackbar.color"
          :multi-line="snackbar.mode === 'multi-line'"
          :timeout="snackbar.timeout"
          :top="snackbar.position === 'top'"
          rounded="pill"
          width="877.27px"
        >
          <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
              <div>{{ $t(snackbar.text) }}</div>
            </v-layout>
          </v-layout>
          <v-btn
            v-if="snackbar.timeout === 0"
            icon
            @click="snackbar.visible = false"
          >
            <v-icon>clear</v-icon>
          </v-btn>
        </v-snackbar>
        <v-container class="login_main_body" fluid>
          <div class="row justify-content-center align-items-center">
            <div
              class="admin_main_row col-xl-3 col-lg-4 col-md-6 col-sm-10 col-xs-12 mt-200"
            >
              <div class="row text-center">
                <div class="col-md-12">
                  <a
                    href="https://www.askgileadmedical.co.kr/"
                    target="(KOREA MEDICAL INFORMATION)"
                  >
                    <div class="img_logo_main"></div>
                  </a>
                  <!-- <img
                    class="img_logo_main"
                    src="@/assets/images/logo_main.png"
                    alt=""
                  /> -->
                </div>
                <span class="logo_text_down">{{
                  $t("memberLogin.title")
                }}</span>
              </div>
              <div class="row mt-30">
                <div class="col-md-12 mb-4">
                  <div id="app">
                    <v-form ref="form" v-model="validRegister" lazy-validation>
                      <v-dialog
                        content-class="vdialog_lockdown"
                        v-model="dialogforLockdown"
                      >
                        <div
                          class="modal-dialog-centered modal_diologe_account_suspend_width"
                        >
                          <div class="modal-content">
                            <div
                              class="modal-header login_dialog_modal_header_second"
                            >
                              <h5 class="modal-title" id="staticBackdropLabel">
                                {{ $t("memberLogin.lockdownDialogtitle") }}
                              </h5>
                            </div>
                            <div class="modal-body p-5">
                              <p>
                                {{
                                  $t(
                                    "memberLogin.lockdownDialogmessagefiveattemptfaild"
                                  )
                                }}
                              </p>

                              <p>
                                {{
                                  $t("memberLogin.lockdownDialogmessageemail")
                                }}
                                {{ this.user.email }}
                                {{
                                  $t(
                                    "memberLogin.lockdownDialogmessageemailunlockinstructions"
                                  )
                                }}
                              </p>

                              <p>
                                {{
                                  $t(
                                    "memberLogin.lockdownDialogmessageemailtemppassword"
                                  )
                                }}
                              </p>
                            </div>
                            <div
                              class="login_dialog_footer_second modal-footer"
                            >
                              <v-btn
                                class="login_dialog_footer_second button"
                                type="button"
                                data-bs-dismiss="modal"
                                @click="dialogClosebtnforLockdown"
                              >
                                {{ $t("memberLogin.getEmail") }}</v-btn
                              >
                            </div>
                            <div class="login_dialog_footer_second_down">
                              <span class="ms-3 span_modal_msg"
                                >{{
                                  $t(
                                    "memberLogin.lockdownDialogmessageemailaddresschanged"
                                  )
                                }}
                                “koreamedinfo@gilead.com”
                                {{
                                  $t(
                                    "memberLogin.lockdownDialogmessageemailcontactus"
                                  )
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </v-dialog>
                      <v-dialog
                        content-class="vdialog_lockdown"
                        v-model="dialogforLoginfailed"
                      >
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <div
                              class="modal-header login_dialog_modal_header_second"
                            >
                              <h5 class="modal-title" id="staticBackdropLabel">
                                {{ $t("memberLogin.loginFaildDialogtitle") }}
                              </h5>
                            </div>
                            <div class="modal-body p-5">
                              <p>
                                <center>
                                  {{
                                    $t(
                                      "memberLogin.loginFaildDialogmessageloginfaild"
                                    )
                                  }}
                                  {{ loginCountforLoginFailed }}
                                  {{
                                    $t(
                                      "memberLogin.loginFaildDialogmessageloginfaildtime"
                                    )
                                  }}
                                </center>
                                <center>
                                  {{
                                    $t(
                                      "memberLogin.loginFaildDialogmessageloginfaildWarning"
                                    )
                                  }}
                                </center>
                              </p>
                            </div>
                            <div
                              class="login_dialog_footer_second modal-footer"
                            >
                              <v-btn
                                class="login_dialog_footer_second button"
                                type="button"
                                data-bs-dismiss="modal"
                                @click="dialogClosebtnforLoginfailed"
                              >
                                {{ $t("memberLogin.ok") }}</v-btn
                              >
                            </div>
                          </div>
                        </div>
                      </v-dialog>

                      <v-container fluid>
                        <v-row class="login_form_row">
                          <!-- Removed coz Qa Suggestion
                          <v-text-field class="login_textfield_label" v-model="user.email"
                              counter="50" :rules="emailRules"
                              :label="$t('memberLogin.id')"
                              :placeholder="$t('memberLogin.idPlaceholder')" required
                              filled rounded dense>
                          </v-text-field> -->
                          <v-text-field
                            class="login_textfield_label"
                            v-model="user.email"
                            :rules="emailRules"
                            :label="$t('memberLogin.id')"
                            :placeholder="$t('memberLogin.idPlaceholder')"
                            required
                            filled
                            rounded
                            dense
                          >
                          </v-text-field>
                        </v-row>
                        <v-row class="login_form_row2">
                          <v-text-field
                            class=""
                            :label="$t('memberLogin.password')"
                            :placeholder="$t('memberLogin.passwordPlaceholder')"
                            @keyup.enter="login"
                            v-model="user.password"
                            type="password"
                            :rules="passwordRules"
                            required
                            filled
                            rounded
                            dense
                          >
                          </v-text-field>
                        </v-row>
                        <v-row class="login_form_row">
                          <v-col class="text-end">
                            <label for=""
                              ><a style="color: black" @click="findIdPage">{{
                                $t("memberLogin.findId")
                              }}</a>
                              |
                              <a style="color: black" @click="findPassPage">{{
                                $t("memberLogin.forgetPassword")
                              }}</a>
                              |
                              <a style="color: black" @click="myInfoPage">{{
                                $t("memberLogin.myInfo")
                              }}</a>
                            </label>
                          </v-col>
                        </v-row>
                        <v-row class="login_form_row">
                          <v-col>
                            <v-btn
                              class="login_button_down"
                              @click="login"
                              rounded
                            >
                              {{ $t("memberLogin.login") }}</v-btn
                            >
                          </v-col>
                        </v-row>
                        <hr class="login_button_down_hr" />
                        <v-row class="login_form_row">
                          <v-col>
                            <v-btn
                              class="login_button_down_button"
                              @click="signupPage"
                              rounded
                              >{{ $t("memberLogin.signup") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
        <!-- Removed coz of Suggestion
                <footer class="main_footer mt-80 bg-light text-center text-lg-start">
                    <div class="text-start p-2">
                        <img class="footer_logo_main ms-3" src="@/assets/images/footer_logo.png" alt="">
                    </div>
                </footer> -->
      </div>
    </div>
    <div class="main" v-show="!toggle">
      <div class="mt-160">
        <h3 class="dormat_account_title text-center">
          {{ $t("memberLogin.noticeofDormantAccountRelease") }}
        </h3>
      </div>
      <div class="mt-70">
        <h3 class="dormat_account_sub_title text-center">
          {{ this.user.email }} {{ $t("memberLogin.dormantStatuslifted") }}
        </h3>
      </div>
      <section class="dormat_account_section mt-30">
        <div class="dormat_account align-self-center">
          <ul
            class="dormat_account_main_ul_class_details align-self-center ms-3 p-3"
          >
            <li>
              {{ $t("memberLogin.dateofSubscription") }} :<b>
                {{
                  (this.user.createdAt[0]
                    ? this.user.createdAt[0].replace('"', "")
                    : null) +
                  "." +
                  parseInt(this.user.createdAt[1]) +
                  "." +
                  parseInt(this.user.createdAt[2])
                }}</b
              >
            </li>
            <li>
              {{ $t("memberLogin.lastLoginDate") }} :<b>
                {{
                  this.user.updatedAt
                    ? (this.user.updatedAt[0]
                        ? this.user.updatedAt[0].replace('"', "")
                        : null) +
                      "." +
                      (this.user.updatedAt[0]
                        ? parseInt(this.user.updatedAt[1])
                        : null) +
                      "." +
                      (this.user.updatedAt[0]
                        ? parseInt(this.user.updatedAt[2])
                        : null)
                    : null
                }}</b
              >
            </li>
            <li>
              {{ $t("memberLogin.dormantAccountConversionDate") }} :<b>
                {{
                  this.user.updatedAt
                    ? (this.user.updatedAt[0]
                        ? parseInt(this.user.updatedAt[0].replace('"', "")) + 1
                        : null) +
                      "." +
                      (this.user.updatedAt[0]
                        ? parseInt(this.user.updatedAt[1])
                        : null) +
                      "." +
                      (this.user.updatedAt[0]
                        ? parseInt(this.user.updatedAt[2])
                        : null)
                    : null
                }}</b
              >
            </li>
            <li>
              {{ $t("memberLogin.dormantReleaseDate") }} :<b>
                {{ this.dormantReleaseDate.replaceAll("-", ".") }}</b
              >
            </li>
          </ul>
        </div>
      </section>
      <div class="d-grid gap-5 d-flex justify-content-center mt-90">
        <v-btn
          class="btn dormat_account_form_btn"
          type="button"
          @click="domantOk"
          >{{ $t("memberLogin.ok") }}
        </v-btn>
      </div>
      <!-- Removed coz of Suggestion
            <footer class="mt-70 main_footer bg-light text-center text-lg-start">
                <div class="text-start p-2">
                    <img class="footer_logo_main ms-3" src="@/assets/images/footer_logo.png" alt="">
                </div>
            </footer> -->
    </div>
  </div>
</template>

<script>
// import '@/assets/css/bootstrap.min.css'
import "@/assets/css/style.css";
import "@/assets/jquery/jquery.min.js";
// import '@/assets/js/bootstrap.bundle.js'
import "@/assets/js/vue.js";
// import '@/assets/js/vuetify.js'
import "@/assets/css/vuetify.min.css";
import { Icon } from "@iconify/vue2";
import MemberDataService from "@/services/MemberDataService";
import { randompwd } from "@/utils/utils";
import jwt from "jsonwebtoken";
export default {
  components: {
    Icon,
  },
  name: "login-component",
  data() {
    return {
      user: {
        id: null,
        email: "",
        password: "",
        createdAt: "",
        updatedAt: "",
      },
      dormantReleaseDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dormantAccountConversionDate: "",
      tempDate: "",
      loginCountforLoginFailed: "",
      toggle: true,
      submitted: "register",
      valid: true,
      pwd: "",
      dialogforLoginfailed: false,
      dialogforLockdown: false,
      IdPassPage: "",
      //Terms
      buttons: [
        {
          color: "warning",
          title: "Warning",
          type: "warning",
        },
      ],
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 2500,
        title: null,
        visible: false,
      },

      validRegister: true,
    };
  },
  methods: {
    login() {
      this.$refs.form.validate();
      if (this.user.email == "" || this.user.password == "") {
        if (this.user.email == "") {
          this.SnackbarShow("email");
          return false;
        } else if (this.user.password == "") {
          this.SnackbarShow("password");
          return false;
        }
      }

      var data = {
        id: 0,
        username: this.user.email,
        // password: this.user.password.split("").reverse().join(""),
        password: this.user.password,
        loginCount: 0,
      };
      console.log(data);
      if (this.$refs.form.validate()) {
        MemberDataService.userCheck(data)
          .then((response) => {
            if (response.data != false) {
              data.loginCount = parseInt(
                JSON.stringify(response.data.loginCount)
              );
              (this.user.createdAt = JSON.stringify(
                response.data.createdAt
              ).split("-")),
                (this.user.updatedAt = JSON.stringify(
                  response.data.updatedAt
                ).split("-")),
                (data.id = JSON.stringify(response.data.id));
              this.user.id = JSON.stringify(response.data.id);
              var createdAtDate = new Date(
                parseInt(this.user.createdAt[0].replace('"', "")) + 1,
                parseInt(this.user.createdAt[1]),
                parseInt(this.user.createdAt[2]),
                parseInt(this.user.createdAt[2].split("T")[1].split(":")[0]),
                parseInt(this.user.createdAt[2].split("T")[1].split(":")[1]),
                parseInt(this.user.createdAt[2].split("T")[1].split(":")[2])
              );
              var updatedAtDate = new Date(
                parseInt(this.user.updatedAt[0].replace('"', "")),
                parseInt(this.user.updatedAt[1]),
                parseInt(this.user.updatedAt[2]),
                parseInt(this.user.updatedAt[2].split("T")[1].split(":")[0]),
                parseInt(this.user.updatedAt[2].split("T")[1].split(":")[1]),
                parseInt(this.user.updatedAt[2].split("T")[1].split(":")[2])
              );
              this.user.updatedAt = JSON.stringify(
                response.data.updatedAt
              ).split("-");
              console.log("Date is the : " + updatedAtDate);
              // this.tempDate=new Date(parseInt(this.user.updatedAt[0].replace('"', '')), parseInt(this.user.updatedAt[1]), parseInt(this.user.updatedAt[2]), parseInt(this.user.updatedAt[2].split('T')[1].split(':')[0]), parseInt(this.user.updatedAt[2].split('T')[1].split(':')[1]), parseInt(this.user.updatedAt[2].split('T')[1].split(':')[2]));

              // this.dormantAccountConversionDate = this.user.updatedAt.setFullYear(this.user.updatedAt.getFullYear() + 1);
              // this.user.updatedAt = updatedAtDate > createdAtDate ? JSON.stringify(response.data.updatedAt).split('-') : null
              // this.user.createdAt[0]=this.user.createdAt[0].replace('"','')
              // const [createdAtyear, createdAtmonth, createdAtday] = this.user.createdAt.split('-');
              // console.log("DT is : " + createdAtDate);
              if (data.loginCount < 4) {
                MemberDataService.validateLogin(data)
                  .then((response) => {
                    switch (response.data) {
                      case "Logged In Successful":
                        localStorage.setItem("memberInfo", data.username);
                        // MISite 이동시키는 부분
                        this.goToMISite3();
                        // this.$router.push("MembersLogged");
                        // MemberDataService.memberLogin(data)
                        //     .then((response) => {
                        //         if (response.data) {
                        //             console.log(response.data)
                        //             this.$router.push("MembersLogged")
                        //         }
                        //         else {
                        //             console.log("Not Exits : "+response.data)
                        //             // this.$router.push("login")
                        //             return false;
                        //         }
                        //         // this.datatableCount = response.data.length
                        //         // console.log(this.datatableCount)
                        //     })
                        //     .catch((e) => {
                        //         if (e.response.status === 401) {
                        //             console.log("Unauthorized")
                        //             // localStorage.clear();
                        //             this.$router.push("login")
                        //         }
                        //         else {
                        //             console.log("Error is : " + e)
                        //         }
                        //     });
                        break;
                      case "Waiting for approval":
                        this.SnackbarShow("Checking medical personnel");
                        break;
                      case "License number mismatch":
                        this.SnackbarShow("License number mismatch");
                        break;
                      case "Birth date mismatch":
                        this.SnackbarShow("Birth date mismatch");
                        break;
                      case "Name discrepancy":
                        this.SnackbarShow("Name discrepancy");
                        break;

                      case "Dormant membership over":
                        //Redirect to Do-1 Page
                        this.toggle = !this.toggle;
                        break;
                      case "Incorrect username or password":
                        data.loginCount += 1;
                        console.log("In counter : " + data.loginCount);
                        MemberDataService.loginCounter(data)
                          .then((response) => {
                            console.log("Counter Response : " + response.data);
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                        (this.loginCountforLoginFailed = data.loginCount),
                          (this.dialogforLoginfailed = true);
                        //this.SnackbarShow("Login failed N times.");
                        break;
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                this.dialogforLockdown = true;
                //this.SnackbarShow("Login Attemps Over");
                return false;
              }
            } else {
              this.SnackbarShow("Invalid ID.");
              return false;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    domantOk() {
      var data = {
        id: 0,
        username: this.user.email,
        password: this.user.password,
        // password: this.user.password.split("").reverse().join(""),
      };
      console.log("Dataa : " + data);
      MemberDataService.userCheck(data)
        .then((response) => {
          if (response.data != false) {
            (this.user.createdAt = JSON.stringify(
              response.data.createdAt
            ).split("-")),
              (this.user.updatedAt = JSON.stringify(
                response.data.updatedAt
              ).split("-")),
              (data.id = JSON.stringify(response.data.id));
            this.user.id = JSON.stringify(response.data.id);

            // this.user.updatedAt = updatedAtDate > createdAtDate ? JSON.stringify(response.data.updatedAt).split('-') : null
            // this.user.createdAt[0]=this.user.createdAt[0].replace('"','')
            // const [createdAtyear, createdAtmonth, createdAtday] = this.user.createdAt.split('-');
            // console.log("DT is : " + createdAtDate);

            //Call to update updatedDate(for dormant account renewal)
            MemberDataService.updateUser(data.id, data)
              .then((response) => {
                //Redirect to MI site
                this.user.id = null;
                this.user.email = "";
                this.user.password = "";
                this.user.createdAt = "";
                this.user.updatedAt = "";
                this.toggle = !this.toggle;
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            this.SnackbarShow("Invalid ID.");
            return false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      //Redirect to MI site
      // this.user = {},
      // this.$router.push("MembersList")
    },
    dialogClosebtnforLockdown() {
      console.log(this.user);
      this.user.password = randompwd();
      // console.log('Running tests...');
      // for (let i = 0; i < 1e5; ++i) {
      //     const pass = this.generateP();
      //     if (!/[A-Z]/.test(pass) || !/[a-z]/.test(pass) || !/[0-9]/.test(pass) || !/[!@#$%^&()_+~`|}{[\]:;?><,./-=]/.test(pass)) {
      //         console.log('generateP() failed with: ' + pass);
      //     }
      // }
      // console.log('Tests finished');
      MemberDataService.emailSent(this.user)
        .then((response) => {
          console.log(
            "Email Response : " +
              response.data +
              " " +
              JSON.stringify(this.user)
          );
          this.user.loginCount = 0;
          MemberDataService.loginCounter(this.user)
            .then((response) => {
              console.log("Counter Response : " + response.data);
              this.user.id = null;
              this.user.email = "";
              this.user.password = "";
              this.user.createdAt = "";
              this.user.updatedAt = "";
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
      this.dialogforLockdown = false;

      //Email API
      // this.user.email = ""
      // this.user.password = ""
      // this.user = {}
    },
    dialogClosebtnforLoginfailed() {
      this.user.id = null;
      this.user.email = "";
      this.user.password = "";
      this.user.createdAt = "";
      this.user.updatedAt = "";
      this.dialogforLoginfailed = false;
    },
    /** @deprecated randompwd 함수로 대체 */
    getRandomChar(str) {
      return str.charAt(Math.floor(Math.random() * str.length));
    },

    findIdPage() {
      this.$router.push({ name: "FindIdPass", params: { items: "FindId" } });
    },

    findPassPage() {
      this.$router.push({ name: "FindIdPass", params: { items: "FindPass" } });
    },

    signupPage() {
      this.$router.push("register");
    },
    // shuffle(array) {
    //     var currentIndex = array.length;

    //     // While there remain elements to shuffle...
    //     while (currentIndex != 0) {

    //         // Pick a remaining element...
    //         var randomIndex = Math.floor(Math.random() * currentIndex);
    //         currentIndex--;

    //         // And swap it with the current element.
    //         [array[currentIndex], array[randomIndex]] = [
    //             array[randomIndex], array[currentIndex]];
    //     }

    //     return array;
    // },
    /** @deprecated randompwd 함수로 대체 */
    generateP(options) {
      var groups = options?.groups ?? [
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        "abcdefghijklmnopqrstuvwxyz",
        "1234567890",
        "@$!%*?&",
      ];
      // Special charaters : !@#$%^&()_+~`|}{[]:;?><,./-=
      length = options?.length ?? 8;
      var pass = groups.map(this.getRandomChar).join("");

      var str = groups.join("");

      for (let i = pass.length; i <= length; i++) {
        pass += this.getRandomChar(str);
      }
      // return this.shuffle(pass);
      return pass;
    },
    SnackbarShow(type) {
      switch (type) {
        case "email":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "memberLogin.pleaseEnterID",
            visible: true,
          };
          break;
        case "password":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "memberLogin.pleaseEnterPassword",
            visible: true,
          };
          break;
        case "Checking medical personnel":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "memberLogin.checkingMedicalPersonnel",
            visible: true,
          };
          break;
        case "License number mismatch":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "memberLogin.licenseNumberMismatch",
            visible: true,
          };
          break;
        case "Birth date mismatch":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "memberLogin.birthDateMismatch",
            visible: true,
          };
          break;
        case "Name discrepancy":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "memberLogin.nameDiscrepancy",
            visible: true,
          };
          break;
        case "Invalid ID.":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "memberLogin.invalidIdPassword",
            visible: true,
          };
          break;
        // case "Login Attemps Over":
        //     this.snackbar = {
        //         color: "warning",
        //         icon: "mdi-alert-circle-outline",
        //         mode: "multi-line",
        //         position: "top",
        //         timeout: 1500,
        //         title: "Warning",
        //         text: "Your account has been locked due to 5 failed login attempts.To unlock [test@mail.com] by mail You will receive an email with unlock instructions.Please check the temporary password in the mail and log in.",
        //         visible: true
        //     };
        //     break;
        // case "Login failed N times.":
        //     this.snackbar = {
        //         color: "warning",
        //         icon: "mdi-alert-circle-outline",
        //         mode: "multi-line",
        //         position: "top",
        //         timeout: 1500,
        //         title: "Warning",
        //         text: "Login failed {{data.loginCount}} times.If you fail 5 times, your account will be locked.",
        //         visible: true
        //     };
        //     break;
      }
    },
    reverse(data) {
      const reverse = data.split("").reverse().join("");
      return reverse;
    },
    myInfoPage() {
      this.$router.push("MyInfo");
    },
    goToMISite3() {
      // const targetUrl = 'https://korea.askgileadmedical.envivent.com/'; // 테스트계
      const targetUrl = "https://www.askgileadmedical.co.kr/"; // 운영계
      const addedUrl1 = ""; //'therapeutic-area/';
      const addedUrl2 =
        "?url=https://www.askgileadmedicalregistration.co.kr/login&token=";

      window.location.href =
        targetUrl + addedUrl1 + addedUrl2 + this.getJwtToken(); //jwtToken;
    },
    getJwtToken() {
      const userID_email = localStorage.getItem("memberInfo");

      // 아래 샘플 작동 참고용 링크와 동일함.
      // https://jwt.io/#debugger-io?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJIQ1AiOiJZRVMiLCJVc2VySUQiOiJzYW1wbGVVc2VySUQifQ.zYNDMJCaUSx03GB4L6ntcAUxYfeE5UK3di_spplHsdA
      // jwt key
      const SECRET_KEY = "Hello2023!@#mi"; // 2023.02.10
      // 넘겨줘야 할 데이터
      const payload = {
        HCP: "YES",
        UserID: userID_email, // 'sampleuser@email.com' // 테스트용
      };
      const jwtToken = jwt.sign(payload, SECRET_KEY, {
        algorithm: "HS256",
      });

      return jwtToken;
    },
  },
  computed: {
    emailRules() {
      // return [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('memberLogin.emailRulesMessage')];
      return [
        (v) =>
          !v ||
          // /^[-a-zA-Z0-9_.]+@[-a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/.test(v) ||
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("memberLogin.emailRulesMessage"),
      ];
    },
    passwordRules() {
      return [
        (v) =>
          !v ||
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/.test(
            v
          ) ||
          this.$t("memberLogin.passwordRulesMessage"),
      ];
    },
  },
};
</script>

<style lang="scss">
// @media screen and (min-width: 600px) and (max-width: 959px) {
//     .login_main_body div{
//         // max-width: 50%;
//     }
// }

.v-application .primary--text {
  color: #828d99 !important;
}

.v-text-field__slot {
  margin-bottom: 5px !important;
}

.vdialog_lockdown {
  width: auto !important;
}

.submit-form {
  max-width: 500px;
}

.pre-formatted {
  white-space: pre;
}
.img_logo_main {
  display: inline-block;
  width: 243px;
  height: 66px;
  background-image: url(~@/assets/images/logo_main.png);
  background-size: cover;
}
.logo_text_down {
  flex-shrink: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  // margin-top: 35px !important;
}

.login_button_down {
  margin-top: 5px !important;
}

.login_button_down_hr {
  margin: 10px !important;
}

// body {
//     background-color: white !important;
// }

.main_footer {
  background-color: #f8f9fa !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.text-end.col {
  font-size: 1rem;
  color: black;
}

.login_dialog_modal_header_second {
  justify-content: center !important;
  text-align: center !important;
}

.p-5 {
  padding: 3rem !important;
}

.modal-body {
  position: relative !important;
  flex: 1 1 auto !important;
  // padding: 1rem !important;
}

.modal_diologe_account_suspend_width {
  width: 500px;
}

.modal-footer {
  padding: 15px;
  text-align: center;
  border-top: 1px solid #e5e5e5;
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}

// @media (min-width: 576px) {
//   .modal-dialog {
//     // max-width: 500px !important;
//     /* margin: 1.75rem auto; */
//   }
// }

.modal-content {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  pointer-events: auto !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem !important;
  outline: 0 !important;
}

// @media (min-width: 768px) {
//   .modal-dialog {
//     // width: 600px;
//     // margin: 0px auto !important;
//   }
// }

.v-dialog {
  font-size: 1rem;
}

.modal-dialog.modal-dialog-centered {
  margin: 0px !important;
}

.admin_login_main_body {
  margin: 0 !important;
  margin-top: 30px !important;
}

ul,
ol {
  margin-top: 0px;
  margin-bottom: 10px;
}

.modal-header {
  min-height: 16.43px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

// .padding-3 {
//   // padding: 0.8rem !important;
// }

@media only screen and (max-width: 576px) {
  .modal_diologe_account_suspend_width {
    width: auto !important;
  }
}

// @media only screen and (max-width: 768px) {
//   .login_main_app {
//     padding: 20px !important;
//   }
// }
</style>
